// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
import 'core-js/stable'
import 'regenerator-runtime/runtime'

require("@rails/ujs").start()
require("turbolinks").start()

require("jquery")
import debug from "log"
import "csv_export"
//import Favorites from "favorites"
import Autocomplete from "autocomplete"
import "csrf"

//require("@rails/activestorage").start()
//require("channels")

import "bootstrap"
import "flash"

document.addEventListener("turbolinks:load", () => {
  var tableCells = $('#filers-top-holdings td.td-clickable-holding');
  tableCells.on('click', function() {
    var tableCell = $(this);
    tableCells.each(function(_ix, elem) {
      var otherTableCell = $(elem);
      if (otherTableCell.text() == tableCell.text()) {
       otherTableCell.toggleClass('bg-danger');
      }
    });
  });

  // AUTH
  const signedIn = document.cookie.indexOf("formthirteen_auth=true") != -1;
  if (signedIn) {
    $('body').addClass("is-signed-in");
  } else {
    $('body').addClass("is-signed-out");
  }

  $("a.scroll-down").on('click', function() {
    $("#scroll-down")[0].scrollIntoView();
  });

  // AUTOCOMPLETE
  Autocomplete({
    selector: '.autocomplete-form',
    url: '/autocomplete',
    debug: $('body').data('debug'),
    minQueryLength: 1
  });
  Autocomplete({
    selector: '#site-search',
    url: '/autocomplete',
    debug: $('body').data('debug'),
    minQueryLength: 1
  });

  // FAVORITES
  // TODO: Is this optimal? Maybe we should initialize in a loop?
  if (signedIn) {
    //if ($('body').hasClass('home') && signedIn) {
    //  window.location.href = '/dashboard'
    //}

    $('.btn-favorite').on('favorite', function(e, elem, id) {
      if (elem.hasClass('favorite')) {
        return;
      }
      var icon = elem.find('.far', 0)
      if (icon.length > 0) {
        icon.addClass('fas', 0)
        icon.removeClass('far', 0)
        elem.addClass('favorite', 0)
      }
      if (elem.hasClass('btn-outline-primary')) {
        elem.removeClass('btn-outline-primary', 0)
        elem.addClass('btn-primary', 0)
      }
    });

    $('.btn-favorite').on('unfavorite', function(e, elem, id) {
      if (!elem.hasClass('favorite')) {
        return;
      }
      var icon = elem.find('.fas')
      if (icon.length > 0) {
        icon.addClass('far', 0)
        icon.removeClass('fas', )
        elem.removeClass('favorite', 0)
      }
      if (elem.hasClass('btn-primary')) {
        elem.addClass('btn-outline-primary', 0)
        elem.removeClass('btn-primary', 0)
      }
    });
  }

  //Favorites.initialize(signedIn);

  // TOOLTIPS
  $('.has-tooltip').tooltip({
    //'position': 'top'
  })
  // NOTE: This is needed because jQuery is only defined within this scope and
  // JS views are rendered in window scope
  window.$ = $
});

// From:
// https://medium.com/@louisemoxy/a-simple-way-to-make-d3-js-charts-svgs-responsive-7afb04bc2e4b
window.responsivefy = function(svg) {
  // container will be the DOM element
  // that the svg is appended to
  // we then measure the container
  // and find its aspect ratio
  const container = d3.select(svg.node().parentNode),
    width = parseInt(svg.style('width'), 10),
    height = parseInt(svg.style('height'), 10),
    aspect = width / height;

  // set viewBox attribute to the initial size
  // control scaling with preserveAspectRatio
  // resize svg on inital page load
  svg.attr('viewBox', `0 0 ${width} ${height}`)
    .attr('preserveAspectRatio', 'xMinYMid')
    .call(resize);

  // add a listener so the chart will be resized
  // when the window resizes
  // multiple listeners for the same event type
  // requires a namespace, i.e., 'click.foo'
  // api docs: https://goo.gl/F3ZCFr
  d3.select(window).on(
    'resize.' + container.attr('id'),
    resize
  );

  // this is the code that resizes the chart
  // it will be called on load
  // and in response to window resizes
  // gets the width of the container
  // and resizes the svg to fill it
  // while maintaining a consistent aspect ratio
  function resize() {
    const w = parseInt(container.style('width'));
    svg.attr('width', w);
    svg.attr('height', Math.round(w / aspect));
  }
}
