document.addEventListener("turbolinks:load", () => {
  $('.btn-download-table').on('click', function() {
    var table = $(this).closest('table');
    var id = table.attr('id');
    var filename = table.data('filename');
    if (table.length == 0) {
      throw "Table not found";
    }
    if (typeof id == 'undefined') {
      throw "csv_export.js: ID attribute not found";
    }
    if (typeof filename == 'undefined') {
      throw "csv_export.js: Filename not found";
    }
    // console.log("id " +id + " " + filename)
    exportTableToCSV("#" + id, filename);
  });
})

window.exportTableToCSV = function(tableID, filename) {
  var csv = [];
  var rows = document.querySelectorAll(tableID + " tbody tr");
  for (var i = 0; i < rows.length; i++) {
    var row = [], cols = rows[i].querySelectorAll("td, th");
    for (var j = 0; j < cols.length; j++){
      var value;
      var td = cols[j];
      if (td.getAttribute('data-value')) {
        value = td.getAttribute('data-value');
      } else {
        value = td.innerText;
      }
      row.push(value);
    }
    csv.push(row.join("\t"));
  }
  // Download CSV file
  downloadCSV(csv.join("\n"), filename);
}

window.downloadCSV = function(csv, filename) {
  var createObjectURL = (window.URL || window.webkitURL || {}).createObjectURL || function(){};
  var csvFile = new Blob([csv], {type: "text/csv"});
  // Download CSV file
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    var link = document.createElement("a");
    // File name
    link.download = filename;
    // Create a link to the file
    link.href = createObjectURL(csvFile);
    //link.href = 'data:attachment/csv,' +  encodeURIComponent(csv)
    // Hide download link
    link.style.display = "none";
    // Add the link to DOM
    document.body.appendChild(link);
    // Click download link
    link.click();
  }
}
