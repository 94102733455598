//// Store CSRF token, so that we can use POST, PUT, etc.
//$(document).ajaxComplete(function( event, response, settings ) {
//  const csrfToken = response.getResponseHeader('X-CSRF-Token');
//  $('#csrf-token').attr('content', csrfToken);
//});
//
//// $(document).ajaxError(function( event, response, settings ) {
////   if (response.status == 401) {
////   }
//// });
//
// Send CSRF token, so that we can use POST, PUT, etc.
//$(document).ajaxSend(function( event, request, settings ) {
//  const csrfToken = $('#csrf-token').attr('content');
//  // TODO: Use cookie as fallback. Set cookie on each request?
//  // CSRF token is needed for all non-GET requests
//  if(settings.type != 'GET' && csrfToken == '') {
//    window.location = '/accounts/sign_in'
//    return
//  } else {
//    request.setRequestHeader("X-CSRF-Token", csrfToken);
//  }
//});
//
jQuery(function($) {
  //
  // Get the CSRF token and include it in the page.
  //
  // NOTE: Remove the no-csrf CSS class from the body to allow rendering of
  // elements that require CSRF token.
  //
  $.getJSON('/csrf.json').then(function(json) {
    $('<meta>')
      .attr('name', "csrf-param")
      .attr('content', json.param)
      .appendTo('head');

    $('<meta>')
      .attr('name', 'csrf-token')
      .attr('content', json.token)
      .appendTo('head');
    $('body').removeClass('no-csrf');
  });
});
