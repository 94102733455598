function autocomplete(options) {
  var form = $(options.selector);
  var input = form.find('.autocomplete-input');
  var resultsElem = form.find('.autocomplete-results');
  var url = options.url;
  var debug = options.debug === true;
  var cache = {};
  var result = null;
  var minQueryLength = options.minQueryLength;
  var debounce = null;
  var waitingFor = null; // Ajax responses might not arrive in order
  var show = function() {
    if(result == null) {
      return;
    }
    resultsElem.fadeIn(100);
  };
  var hide = function() {
    resultsElem.fadeOut(100);
  };
  var log = function(msg) {
    if(debug) {
      console.log(msg);
    }
  };
  //console.debug(input)
  //console.debug(resultsElem)
  // Hide when clicking outside autocomplete elements
  $('body').click(function(){
    hide();
  });
  // Stop event propagation so that body click handler is not triggered
  input.click(function(e){
    e.stopPropagation();
  });
  var renderResults = function(query, results) {
    if (query !== waitingFor) {
      log("Skip: " + query + " !== " + waitingFor)
      return;
    }
    log("Rendering results for " + query);
    resultsElem.html(results);
    if (results != undefined) {
      show();
    } else {
      hide();
    }
  };
  var search = function() {
    var query = input.val();
    log("Searching for '" + query + "'")
    waitingFor = query;
    if (query in cache) {
      log('Using cache')
      renderResults(query, cache[query]);
    } else {
      if (query.length >= minQueryLength) {
        log("Performing XHR query " + query);
        //console.log(form.serialize())
        //console.log(query)
        $.ajax({url: url, data: form.serialize(), type: 'GET'}).then(function(data) {
          result = data;
          cache[query] = result;
          renderResults(query, result);
        });
      } else {
        renderResults(query, null);
      }
    }
  };
  var searchWithDebounce = function(e) {
    clearTimeout(debounce);
    debounce = setTimeout(search, 100);
  };
  input.on('blur', hide);
  input.on('focus', show);
  input.on('keyup', searchWithDebounce);
};

module.exports = autocomplete;
