var debug = $('html').data('debug') == true;
var logger = function(msg) {};
var hasConsole = (console !== undefined && console.debug !== undefined);

if(debug && hasConsole) {
  logger = function(msg) {
    console.log(msg);
  }
  logger("log: init");
};

export default logger;
