import debug from "log"

var UnobtrusiveFlash = {};

(function() {
  debug("flash: init")
  // Delete the cookie regardless of the domain it was set from
  // Partial credit to http://stackoverflow.com/a/2959110/6678
  function nukeCookie(cookieName) {
      var yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      var hostParts = window.location.hostname.split('.').reverse();
      var expireHost = hostParts.shift();
      $.each(hostParts, function(i,part) {
        expireHost = part + '.' + expireHost;
        document.cookie = cookieName+'=; path=/;expires='+yesterday+'; domain='+expireHost;
      });
      document.cookie = cookieName+'=; path=/;expires='+yesterday+'; domain=';
  }

  // Extracts flash array stored in cookie and clears the cookie
  function extractFlashFromCookies() {
    debug("flash: read")
    var data = null;
    if (document.cookie && document.cookie !== '') {
      var cookies = document.cookie.split(';');
      var name = 'flash';
      var cookieValue = null;

      for (var i = 0; i < cookies.length; i++) {
          var cookie = jQuery.trim(cookies[i]);
          if (cookie.substring(0, name.length + 1) == (name + '=')) {
            // replace fixes problems with Rails escaping. Duh.
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1).replace(/\+/g,'%20'));
            if (cookieValue.length > 0) break; // there might be empty "flash=" cookies
          }
      }

      try {
        data = $.parseJSON(cookieValue);
      } catch(e) {
      }

      nukeCookie('flash');
    }

    return data;
  }

  UnobtrusiveFlash.showFlash = function(flashMessages) {
    if (flashMessages !== null) {
      debug("flash: show " + flashMessages)
      $.each(flashMessages, function(_, flashMessage) {
        $(window).trigger('rails:flash', {type: flashMessage[0], message: flashMessage[1]});
      });
    }
  };

  // Reads flash messages from cookies and fires corresponding events
  UnobtrusiveFlash.showFlashFromCookies = function() {
    UnobtrusiveFlash.showFlash(extractFlashFromCookies());
  };

  // We want to remove cookies from the flash as soon as possible, but we only want to show them after all the scripts have loaded,
  // including any flash handlers
  // var pageCookies = extractFlashFromCookies();
  //$(window).on('load', function() {
  //  UnobtrusiveFlash.showFlash(pageCookies);
  //});

  $(document).on('turbolinks:load', UnobtrusiveFlash.showFlashFromCookies);
  // TODO: Why is this needed?
  //$(document).ajaxComplete(function(event,request,options) {
  //  UnobtrusiveFlash.showFlashFromCookies();
  //});
})();

// Unobtrusive flash UI implementation with Bootstrap 3
// For sites that use Bootstrap http://getbootstrap.com/
//
// Declare a .unobtrusive-flash-container wherever you want the messages to appear
// Defaults to .container, .container-fluid (core Bootstrap classes), or just the body tag, whichever is present

UnobtrusiveFlash.flashOptions = {
  type: 'notice',
  timeout: 0,
  mapping: {
    notice: 'primary',
    alert: 'info',
    error: 'danger'
  }
};

(function() {

  UnobtrusiveFlash.showFlashMessage = function(message, options) {
    options = $.extend(UnobtrusiveFlash.flashOptions, options);

    // Workaround for common Rails flash type to match common Bootstrap alert type
    switch (options.type) {
      case 'notice':
      case 'alert':
      case 'error':
        options.type = options.mapping[options.type]
    }

    var $flash = $('<div class="alert alert-'+options.type+'"><button type="button" class="close" data-dismiss="alert">&times;</button>'+message+'</div>');

    var $flashContainer = $($('#flash-container')[0] || $('.container')[0] || $('.container-fluid')[0] || $('body')[0]);
    $flashContainer.prepend($flash);

    //$flash.hide().delay(300).slideDown(100);

    $flash.alert();

    if (options.timeout > 0) {
      setTimeout(function() {
        $flash.alert('close');
      },options.timeout);
    }
  };

  var flashHandler = function(e, params) {
    UnobtrusiveFlash.showFlashMessage(params.message, {type: params.type});
  };

  $(window).bind('rails:flash', flashHandler);
})();

export default UnobtrusiveFlash;
